.modalGroupMe {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 30%;
    top: 28%;
    height: 350px;
    width: 350px;
    overflow: auto;
    background-color: rgb(0,0,0,0.4);
}

.modalGroupMeContent {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: auto;
    height: 300px;
    width: 300px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%; 
}

.modalGroupMeImage {
    height: 250px;
    width: 250px;
}

.closeButton {
    align-self: end;
    padding: 1px;
    height: 20px;
    width: 55px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    color: #fefefe;
    background-color: rgb(255,0,0);
    cursor: pointer;
}

.closeButton:hover,
.closeButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.groupMeHeadingText {
    align-self: flex-start;
    padding: 2px;
}

.groupMeTextArea {
    display: flex;
    padding-top:2px;
}

.groupMeContextArea {
    height: 215px;
    width: 215px;
}

.sendGroupMeButton {
    align-self: end;
    padding: 1px;
    height: 30px;
    width: 55px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    color: #fefefe;
    background-color: rgb(66,108,245);
    cursor: pointer;

}

.sendGroupMeButton:hover,
.sendGroupMeButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
