
.title {
    font-weight: bold;
}

.padding1em {
    padding: 1em;
}

.boldFont {
    font-weight: bold;
}

.courtAssignLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    background-color: black;
    padding: 3px;
    margin: 3px;
    font-family: Arial, Helvetica, sans-serif;
}

.courtAssignLayout > div {
    /*background-color: rgba(255, 255, 255, 0.8); */
    background-color: #a9a9a9;
    text-align: left;
    /*padding:10px; */
    font-size: 13px;
    padding-top: 1px; 
    padding-bottom: 2px;
    padding-left: 2px;
}

.courtNumber {
    background-color: #a9a9a9;
}

.courtPlayerPlacement {
    display: flex;
    flex-direction: row;
}

.playerLabel {
    min-height: 22px;
}

.playerName {
    width: 175px;
    min-height:24px;
    background-color: white;
    padding-top: 2px;
    padding-left: 3px;
    white-space: nowrap;
    overflow: hidden;
}

.playerRemoveButton {
    margin-top: 2px;
    margin-left: 2px;
    min-height:22px;
    color: white;
    background-color: black; 
    font-weight: bold;
    border: solid black 2px;
    text-align: center;
    border-radius: 8px;
}
