.playerListRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #f8f8ff;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

/*
.playerListRow > div {
    background-color: white;

}
*/

.playerActive {
    background-color: lightgreen;
}

.playerName {
    padding: 0.25em;
    padding-left: 15px;
    background-color: #f8f8ff;
}

.playerNameActive {
    background-color: lightgreen;
}

/* .availability {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    */
    /*padding-bottom: 0.5rem; 
    border-bottom: 2px solid #ccc;
    */
    /*
    padding-top: 4px;
    padding-bottom: 2px; 
}
*/



