.playersLayout {
    /*
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.25rem;
    */
    /*background-color: #2196F3; */
    /*padding: 5px 5px 5px 1px;  */
    padding-top: 5px;
    padding-bottom: 5px;
    /*width: 100%;  */
    /*width: 1095px; */
    /*width: 1090px; */
    /*overflow-x: hidden;  */
}

.playersLayout > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    /*padding-left: 5px; */
    font-size: 12px;
}

/*
.playerCategoriesLayout {
    display: flex;
    flex-direction: row;
}
*/

.playerCategoryLayout {
    display: flex;
    flex-direction: column;
}

.levelHeading {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 5px 0px 5px;
    min-height: 25px;
    max-height: 25px;
    border-right: solid;
}

.levelPlayerBlock {
    /*padding-left: 5px; */
    /*background-color: black; */
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    /*border-right: 1px solid black; */
    /*border-left: 1px solid black; */
    display: flex;
    flex-direction: column;
    height:600px;
    overflow-y: scroll;
}

.levelPlayerSingleBlock {
    /*padding-left: 5px; */
    /*background-color: black; */
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    /*border-right: 1px solid black; */
    /*border-left: 1px solid black; */
    display: flex;
    flex-direction: column;
    height:60px;
    overflow-y: scroll;
}

.levelPlayerBlock > div {
    /*background-color: white; */
    /*border-right: solid 1px; */
}

 .availability {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    /*padding-bottom: 0.5rem; 
    border-bottom: 2px solid #ccc;
    */
    padding-top: 4px;
    padding-bottom: 2px; 
}


