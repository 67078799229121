.playerAddEdit {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 20px;
    /*justify-content: flex-start; */
    /*
    background-color: #f8f8ff;
    border-top: 1px solid black;
    */
    /*
    width: 130px;
    height: 25px;
    */
}

/*
.playerListRow > div {
    background-color: white;

}
*/

.playerAddButton {
    align-self: end;
    margin-top: 4px;
    padding: 1px;
    height: 30px;
    width: 65px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    border-radius: 8px;
    color: #fefefe;
    background-color: rgb(66,108,245);
    cursor: pointer;
}

.playerAddButton:hover,
.playerAddButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.playerAddEditForm {
    display: flex;
    flex-direction: column;
    width: 220px;
}

.inputDivSection {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
}

.inputLabel {
    padding-right: 5px;
    font-weight: bold;
}

.inputLabels {
    padding-right: 5px;
    font-weight: bold;
}

.inputLabelGender {
    padding-right: 10px;
    font-weight: bold;
}

.inputLabelRating {
    padding-right: 16px;
    font-weight: bold;
}

.inputBoxName {
    width: 170px;
    padding-bottom: 3px;
}

.inputBox {
    width: 82px;
    padding-bottom: 1px;
}


