.playerListRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #f8f8ff;
    border-top: 1px solid black;
    width: 138px;
    height: 25px;
}

/*
.playerListRow > div {
    background-color: white;

}
*/

.playerActive {
    background-color: lightgreen;
    color: black;
}

.playerNameButton {
    background-color: #f8f8ff;
    width: 138px;
    height: 25px;
    cursor: pointer;
}

.playerNameActive {
    background-color: lightgreen;
}

.playerNameButton:hover,
.playerNameButton:focus {
    color: #000;
    background-color: greenyellow;
    text-decoration: none;
    cursor: pointer;
}