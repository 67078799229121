
.title {
    font-weight: bold;
}

.matchesLayout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3px;
    /*
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.25rem;
    */
    /*background-color: #2196F3; */
    padding: 3px 5px 5px 5px; 
    border-top: solid 5px;
    height: 540px; 
    overflow-y: scroll; 
}

.matchesLayoutRO {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3px;
    /*
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.25rem;
    */
    /*background-color: #2196F3; */
    padding: 3px 5px 5px 5px; 
    border-top: solid 5px;
    /*height: 540px; */
    height: 100%;
    /*overflow-y: scroll; */
}

.matchesLayout > div {
    /*
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    */
    padding: 3px;
}

.padding1em {
    padding: 1em;
}

.boldFont {
    font-weight: bold;
}