
.header {
    display: flex;
    /*justify-content: center; */
    margin: 0.75rem auto;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    max-width: 60rem; 
}

.headerTitle {
    display: flex;
    text-decoration: none;
    font-size: 19px; 
    font-weight: bold;
    width: 40rem;
    text-align: center;
    justify-content: center;
    margin: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.headerFiller {
    display: flex;
    align-items: flex-start;
    width: 6rem;
    margin-left: 10px;
}
