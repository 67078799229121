.player {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ccc;
}

/*
.playerLayout {
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 2rem;
    background-color: #2196F3;
    padding: 5px;
}

.playerLayout > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding:20px 0;
    font-size: 15px;
}
*/

.playerName {
    /*padding: 2px; */
    padding-top: 8px;
    padding-bottom: 2px;
    padding-left: 7px;
    /*
    min-height: 32px;
    max-height: 32px;
    */
    min-width: 67px;
    max-width: 67px; 
    border-bottom: solid 1px black;
    border-left: solid 4px black; 
    border-right: solid 2px black;
    background-color: #6495FE;
    color: white;
}

.boldFont {
    font-weight: bold;
}