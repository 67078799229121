.header {
    display: flex;
    /*justify-content: center; */
    margin: 0.75rem auto;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    max-width: 60rem; 
    /*width: 60rem; */
}

.headerName {
    display: flex;
    align-items: flex-start;
    width: 6rem;
    margin-left: 10px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.links {
    display: flex;
    /*gap: 2rem; */
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ccc;
    /*width: 33rem; */
}

.links a {
    text-decoration: none;
    font-size: 14px;
    width: 5rem;
    display: inline-block;
    text-align: center;
    color: #6d6674;
}

.active {
    font-weight: bold;
    color: #35224a;
}

.link {
    width: 5rem;
    height: 2.5rem;
}

/*
.link:hover,
.link:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
    */