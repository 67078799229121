.title {
    font-weight: bold;
}

.matchInfoBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

/*
.matchLayout {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    gap: 2rem;
    background-color: #2196F3;
    padding: 5px;
}

.matchLayout > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding:20px 0;
    font-size: 15px;
}
*/

.padding1em {
    padding: 1em;
}

.boldFont {
    font-weight: bold;
}