.matchListRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #f8f8ff;
    border-top: 1px solid black;
    
    width: 85px;
    height: 105px;
    
    /*
    min-height: 80px;
    max-height: 80px;
    min-width: 78px;
    max-width: 78px;
    */
}

.matchCard {
    padding: 5px;
    border: solid 4px;
    min-width: 100px;
    min-height: 250px;
    max-width: 145px;
    background-color: lightgray;
    margin: 1px;
}

.displayMatchCardTop {
    display: flex;
    justify-content: center;
}

.buttonMatchInfo {
    border-left: solid 2px;
    border-top: solid 2px;
}

.matchInfoStuffButton {
    font-weight: bold;
    font-size: 12px;
    padding: 0px;
    width: 91px;
    height: 90px;
    background-color: #f8f8ff;
    cursor: pointer;
}

.matchInfoStuffButtonActive {
    background-color: lightgreen;
    color: black;
}

.matchInfoStuffButton:hover,
.matchInfoStuffButton:focus {
    color: #000000;
    background-color: greenyellow;
    text-decoration: none;
    cursor: pointer;
}

/*
.playerListRow > div {
    background-color: white;

}
*/

.matchesDetail {
    /*background-color: rgba(255, 255, 255); */
    background-color: #6495ed; 
    color: white;
    text-align: center;
    padding: 4px 0px 2px 0px;
    font-size: 12px;
    /*
    padding-left:2px;
    padding-right: 2px;
    */
    /*
    min-height: 80px;
    max-height: 80px;
    min-width: 78px;
    max-width: 78px;
    */
    /*width: 90px; */
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    width: 80px;
    height: 90px;
}

.title {
    font-size: 12px;
    font-weight: bold;
}

.matchActive {
    background-color: lightgreen;
    color: black;
}

.matchButton {
    background-color: #f8f8ff;
    width: 90px;
    height: 103px;
    cursor: pointer;
}

.matchNameActive {
    background-color: lightgreen;
}

.matchButton:hover,
.matchButton:focus {
    color: #000;
    background-color: greenyellow;
    text-decoration: none;
    cursor: pointer;
}