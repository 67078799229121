.title {
    font-weight: bold;
    padding: 10px;
    width: 165px;
}

.matchInfoStuff {
    font-weight: bold;
    font-size: 11px;
    padding: 0px;
    width: 91px;
    height: 63px;
}

.matchInfoPastCurrentDate {
    background-color: black;
    color: white;
}

.matchInfoStuffActive {
    background-color: rgb(255, 127, 80);
    color: black;
}

.padding1em {
    padding: 1em;
}

.boldFont {
    font-weight: bold;
}