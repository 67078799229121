.adminMatchesLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    background-color: black;
    padding: 3px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.adminMatchesLayout > div {
    /*background-color: rgba(255, 255, 255, 0.8); */
    background-color: #a9a9a9;
    text-align: center;
    /*padding:10px; */
    font-size: 13px;
}

.matchesLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    display: flex;
    flex-direction: row;
    /*background-color: black; */
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 20px;
}

.matchesLayout > div {
    /*background-color: rgba(255, 255, 255, 0.8); */
    /*background-color: rgba(85, 85, 200, 0.8); */
    background-color: #000000;
    text-align: center;
    /*padding:10px; */
    font-size: 15px;
}


.componentSection {
    display: flex;
    flex-direction: column;
}



.matchColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.matchesLabel {
    display: flex;
    padding: 3px;
    border-top: solid 3px;
    border-right: solid 4px;
    border-bottom: solid 2px;
    font-weight: bold;
    min-width: 121px;
    max-width: 121px;
    background-color: bisque;
}

.addEditMatchSection {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    height: 185px;
}

.addEditMatchesLabel {
    display: flex;
    padding-top: 10px;
    padding-left: 15px;
    border-top: solid 3px;
    /*
    border-right: solid 4px;
    border-bottom: solid 2px;
    */
    font-weight: bold;
    min-width: 44px;
    max-width: 44px;
    height: 35px;
    background-color: rgb(255,127,80); 
    align-items: flex-start;
}

.addEditLabelSection {
    display: flex;
    flex-direction: column;
}

.clearFieldsButton{
    align-self: end;
/*    margin-top: 5px; */
    padding: 1px;
    height: 50px;
    width: 52px; 
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    border-radius: 8px;
    color: #fefefe;
    background-color: rgb(66,108,245);
    cursor: pointer;
}

.clearFieldsButton:hover,
.clearFieldsButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.addEditMatchInput {
    display: flex;
    background-color: #f8f8ff;
    border-top: solid 3px;
}




