.matchesLayout {
    display: flex;
    flex-direction: row;
    /*padding: 5px 1px 1px 5px; */
    padding-top: 5px;
    padding-bottom: 1px;
    /*width: 100%; */
    /*width: 1090px; */
}

.matchesDetail {
    /*background-color: rgba(255, 255, 255); */
    background-color: #6495ed; 
    color: white;
    text-align: center;
    padding:5px 0;
    font-size: 12px;
    /*
    padding-left:2px;
    padding-right: 2px;
    */
    min-height: 80px;
    max-height: 80px;
    min-width: 78px;
    max-width: 78px;
    /*width: 90px; */
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
}


.matchInfoBlock {
    display: flex;
    flex-direction: column;
}

.titleMatchInfoMatches {
    font-size: 15px;
    font-weight: bold;
    min-height: 36px;
    max-height: 36px;
    min-width: 75px;
    max-width: 75px;
    background-color: #d3d3d3;
    padding-top: 7px; 
    align-content: start;
    border-top: solid 2px;
    /*border-right: solid 2px; */
    border-left: solid 4px;
    border-bottom: solid 2px;
    text-align: right;
    /*padding-left: 2px; */
}

.titleMatchInfoPlayers {
    font-size: 15px;
    font-weight: bold;
    min-height: 38px;
    max-height: 38px;
    min-width: 75px;
    max-width: 75px;
    background-color: rgba(255,127,80); 
    /*background-color: #d3d3d3;  */
    /*background-color: #6495FE; */
    padding-top: 7px; 
    align-content: center;
    /*border-right: solid 2px; */
    border-left: solid 4px;
    border-bottom: solid 2px;
    text-align: left;
    padding-left: 1px; 
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.scrollBarFiller {
    background-color: black;
    min-width: 10px;
    max-width: 10px;
}
