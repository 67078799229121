.lineupLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    background-color: black;
    padding: 5px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.lineupLayout > div {
    /*background-color: rgba(255, 255, 255, 0.8); */
    background-color: #a9a9a9;
    text-align: center;
    /*padding:10px; */
    font-size: 13px;
}

.lineupLegend {
    padding: 5px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

.componentSection {
    display: flex;
}

.leftSidePlayersLabel {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    gap: 0.25rem;
    padding: 3px;
    border-right: solid 4px;
    border-bottom: solid 2px;
    font-weight: bold;
    min-width: 15px;
    max-width: 15px;
    background-color: bisque;
}

.leftSideMatchesLabel {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    gap: 0.25rem;
    padding: 3px;
    border-top: solid 5px;
    border-right: solid 4px;
    border-bottom: solid 2px;
    font-weight: bold;
    min-width: 15px;
    max-width: 15px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #6495ed;
}

.levelHeading {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 2px 2px 10px;
    /*background-color: #0C87D5; */
    background-color: rgb(80,80,84);
    color: rgb(255,255,255);
}

.levelPlayerBlock {
    /*padding-left: 5px; */
    /*background-color: black; */
    border-top: 2px solid black;
    border-bottom: 2px solid black;

}

.playerColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ratingColumn {
    padding: 0.25px;
    width: 130px;
    border-right: solid;
}

.ratingGroupColumnBegin {
    padding: 0.25px;
    width: 130px;
    border-right: solid;
    border-left: solid 5px black;
}

.ratingGroupColumn {
    padding: 0.25px;
    width: 130px;
    border-right: solid 9px black;
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    margin-top: 0.25rem;
    max-width: 40rem;
    font-weight: bold;
    color: #000000;
}

.buttonGroupMe {
    margin-top: 3px;
    margin-left: 3px;
    font-size:11px;
    padding: 1px;
    height: 40px;
    width: 55px;
    border: solid black 3px; 
    cursor: pointer;
    color: white;
    background-color: rgb(66,108,245);
}

.buttonGroupMe:hover,
.buttonGroupMe:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}