.playerColumn {
    /*
    display: grid;
    grid-template-columns: repeat(9, 1fr); 
    */
    /*gap: 0.25rem; */
    /*background-color: rgba(85, 85, 200, 0.8);  */
    background-color: #000000;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    /*
    border-right: 1px solid black;
    border-left: 2px solid black;
    */
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    /*max-width: 70px; */
    /*
    max-height: 155px;
    min-height: 155px;
    */
}

/*
.playerColumn > div {
    background-color: #d3d3d3;
}
    */

.playerNameBlock {
    background-color: #555;
}

.playerButtonBlock {
    padding: 2px 0px 2px 0px;
    /*padding-left: 2px;
    padding-right: 2px;
    */
    /*
    min-height: 42px;
    max-height: 46px;
    */
    height: 2.25rem;
    min-width: 78px;
    max-width: 78px;
    background-color: #d3d3d3;
    border-left: 2px solid black;
    border-right: 2px solid black;
}

/* .availability {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    */
    /*padding-bottom: 0.5rem; 
    border-bottom: 2px solid #ccc;
    */
    /*
    padding-top: 4px;
    padding-bottom: 2px; 
}
*/

.availabilityButton {
    margin-right: 3px;
    font-size: 10px;
    font-weight: bold;
    width: 15px;
    height: 10px;
    border: 2px solid #d3d3d3;
    border-radius: 2px;
    padding: 5px 8px 5px 8px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

/*******  Yes Button ******/
.yesButton {
    /*background-color: #008000; */
    background-color: #f5f5f5; 
}

.yesButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #2ecc71;
}

.yesButton:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

.yesButtonSet {
    /*background-color: #008000; */
    background-color: #2ecc71;
}

.yesButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ccff99;
}

.yesButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}


/*******  Mystery Button ******/
.mysteryButton {
    /*background-color: #ffff00; */
    background-color: #f5f5f5; 
}

.mysteryButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #008000; */
    background-color: #ffff00; 
}

.mysteryButton:active {
    box-shadow: 1px 3px 4px #aaa inset, 0px 2px 3px #fff;
}

.mysteryButtonSet {
    /*background-color: #008000; */
    background-color: #ffff00; 
}

.mysteryButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ffff99;
}

.mysteryButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

    
/*******  No Button ******/
.noButton {
    /*background-color: #ff0000; */
    background-color: #f5f5f5; 
}

.noButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #008000; */
    background-color: #ff0000; 
}

.noButton:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

.noButtonSet {
    /*background-color: #008000; */
    background-color: #ff0000; 
}

.noButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ff6666;
}

.noButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}


