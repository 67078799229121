.passInput {
    /*
    display: flex;
    flex-direction: row;
    */
    width: 65px;
    border: white;
}

.passButton {
    width: 20px;
    border: white;
}