
.headerLogin {
    margin-top: 1.5rem;
    margin-left: 20px;
    margin-bottom: 0.25rem;
    max-width: 50rem;
}

.headerLogin p {
    text-decoration: none;
    font-size: 22px; 
    font-weight: bold;
    width: 17rem;
    display: inline-block;
    text-align: center;
    margin: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.headerLogin div {
    margin-top: .5rem;
    margin-bottom: 0.25rem;
    margin-left: 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.phoneNumOTPLayout{
    display: flex;
    flex-direction: column;
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 20px;
}

.examplePhone {
    font-size: 12px;
}

.phoneNumContent {
    padding-top: 5px;
}

.phoneNumInput {
    /*
    display: flex;
    flex-direction: row;
    */
    width: 120px;
    height: 25px;
    border: white;
}

.phoneNumButton {
    margin-left: 6px;
    padding: 1px;
    height: 25px;
    width: 60px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    background-color: rgb(66,108,245);
    color: #fefefe;
    cursor: pointer;
}

.phoneNumButtonDisabled {
    margin-left: 6px;
    padding: 1px;
    height: 25px;
    width: 60px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #999999; 
    background-color: #cccccc;
    color: #666666;
    cursor: default;
    opacity: 0.5;
}

.otpLayout{
    display: flex;
    flex-direction: column;
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 65px;
    margin-left: 20px;
}

.otpInput {
    /*
    display: flex;
    flex-direction: row;
    */
    width: 120px;
    height: 25px;
    border: white;
}

.otpContent {
    padding-top: 5px;
}

.otpButton {
    margin-left: 6px;
    padding: 1px;
    height: 25px;
    width: 90px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    background-color: rgb(66,108,245);
    color: #fefefe;
    cursor: pointer;
}

.otpButtonDisabled {
    margin-left: 6px;
    padding: 1px;
    height: 25px;
    width: 90px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #999999; 
    background-color: #cccccc;
    color: #666666;
    cursor: default;
    opacity: 0.5;
}

.errorPhoneLayout{
    display: flex;
    flex-direction: column;
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    margin-left: 20px;
}

.errorPhoneContent {
    padding-top: 5px;
    color: red;
    font-weight: bold;
}

.errorOTPLayout{
    display: flex;
    flex-direction: column;
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    margin-left: 20px;
}

.errorOTPContent {
    padding-top: 5px;
    color: red;
    font-weight: bold;
}

.resendphoneNumButton {
    margin-left: 6px;
    padding: 1px;
    height: 25px;
    width: 130px;
    font-size: 15px;
    font-weight: bold;
    border: solid black 2px;
    background-color: red;
    color: #fefefe;
    cursor: pointer;
}

.loader {
    display: flex;
    margin: 3em; 
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 3em;
}

.spinner {
    display: block;
    font-size: 16px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
  }
  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #000000;
    }
}
