
.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 1rem auto;
    margin-top: 0.25rem;
    max-width: 40rem;
}

.footerText {
    text-decoration: none;
    font-size: 15px; 
    font-weight: bold;
    width: 12rem;
    display: inline-block;
    text-align: center;
    margin: 2px;
}