.availabilityUpdateLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    display: flex;
    flex-direction: row;
    /*background-color: black; */
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 20px;
}

.availabilityUpdateLayout > div {
    /*background-color: #a9a9a9; */
    background-color: #d3d3d3;
    text-align: center;
    /*padding:10px; */
    font-size: 15px;
}