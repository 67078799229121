
.title {
    font-weight: bold;
}

.matchCard {
    padding: 5px;
    border: solid 4px;
    min-width: 100px;
    min-height: 250px;
    max-width: 145px;
    background-color: lightgray;
    margin: 1px;
}

.matchCardRO {
    padding: 5px;
    border: solid 4px;
    min-width: 100px;
    min-height: 250px;
    max-width: 105px;
    background-color: lightgray;
    margin: 1px;
}

.matchCardActive {
    background-color: rgb(255,127,80); 
}

.padding1em {
    padding: 1em;
}

.boldFont {
    font-weight: bold;
}

.bottomBorder {
    border-bottom: solid 2px;
}

.displayMatchCardTop {
    display: flex;
    justify-content: right;
}

.displayMatchCardTopRO {
    display: flex;
    justify-content: center;
}

.buttonMatchInfo {
    border-left: solid 2px;
    border-top: solid 2px;
}

.buttonMatchInfoRO {
    border-left: solid 2px;
    border-top: solid 2px;
    border-right: solid 2px;
    border-bottom: solid 2px;
}

.buttonGroupMe {
    font-size:11px;
    padding: 1px;
    width: 45px;
    border-top: solid black 3px;
    border-right: solid black 3px;
    border-bottom: solid black 1px;
    border-left: solid black 2px;
    cursor: pointer;
    color: white;
    background-color: rgb(66,108,245);
}

.buttonGroupMe:hover,
.buttonGroupMe:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.sendGroupMeButton {
    cursor: pointer;
    color: black;
    font-weight: bold;
    background-color: lightgreen;
    /*
    position: relative;
    bottom: 11px;
    */
    height: 35px;
}

/*
.matchCard > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding:20px 0;
    font-size: 15px;
}
*/