.availabilityLayout {
    /*display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    */
    /*background-color: #2196F3; */
    display: flex;
    flex-direction: column;
    /*background-color: black; */
    background-color: #f1f0f2;
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 20px;
}

.availabilityLayout > div {
    /*background-color: rgba(255, 255, 255, 0.8); */
    /*background-color: rgba(85, 85, 200, 0.8); */
    background-color: #000000;
    text-align: center;
    /*padding:10px; */
    font-size: 15px;
    width: 47rem;
}