.availability {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 2.0rem;
    padding-top: 2px;
}

.availabilityButton {
    margin: 1px;
    font-size: 9px;
    font-weight: bold;
    width: 18px;
    height: 2px;
    border: 2px solid #000000;
    border-radius: 14px;
    padding: 1px 8px 8px 8px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.availabilityButtonRO {
    margin: 1px;
    font-size: 9px;
    font-weight: bold;
    width: 18px;
    height: 2px;
    border: 2px solid #000000;
    border-radius: 14px;
    padding: 1px 8px 8px 8px;
    display: inline-block;
    position: relative;
}

/*******  Yes Button ******/
.yesButtonRO {
    /*background-color: #008000; */
    background-color: #f5f5f5; 
}

.yesButton {
    /*background-color: #008000; */
    background-color: #f5f5f5; 
}

.yesButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #2ecc71;
}

.yesButton:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

.yesButtonSetRO {
    /*background-color: #008000; */
    background-color: #2ecc71;
}

.yesButtonSet {
    /*background-color: #008000; */
    background-color: #2ecc71;
}

.yesButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ccff99;
}

.yesButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}


/*******  Mystery Button ******/
.mysteryButton {
    /*background-color: #ffff00; */
    background-color: #f5f5f5; 
}

.mysteryButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #008000; */
    background-color: #ffff00; 
}

.mysteryButton:active {
    box-shadow: 1px 3px 4px #aaa inset, 0px 2px 3px #fff;
}

.mysteryButtonSet {
    /*background-color: #008000; */
    background-color: #ffff00; 
}

.mysteryButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ffff99;
}

.mysteryButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

    
/*******  No Button ******/
.noButton {
    /*background-color: #ff0000; */
    background-color: #f5f5f5; 
}

.noButtonRO {
    /*background-color: #ff0000; */
    background-color: #f5f5f5; 
}

.noButton:hover {
    text-decoration: none;
    color: #555;
    /*background: #008000; */
    background-color: #ff0000; 
}

.noButton:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}

.noButtonSet {
    /*background-color: #008000; */
    background-color: #ff0000; 
}

.noButtonSetRO {
    /*background-color: #008000; */
    background-color: #ff0000; 
}

.noButtonSet:hover {
    text-decoration: none;
    color: #555;
    /*background: #f5f5f5; */
    /*background: #008000; */
    background-color: #ff6666;
}

.noButtonSet:active {
    box-shadow: 1px 3px 4px #34495e inset, 0px 2px 3px #fff;
}


